import React from "react"
import * as styles from "../scss/background.module.scss"

const Background = ({css}) => {
  const cssStyles = [];
  css.map((c) => {
    cssStyles.push(styles[c]);
    return null;
  });
  return (
    <div className={`${cssStyles.join(' ')}`}></div>
  )
}

export default Background